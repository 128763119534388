.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Adjust for better spacing on smaller screens */
  align-items: center;
  width: 100%;
  background-color: #fcfbf4;
  padding: 10px 5%; /* Add padding for better touch targets */
  box-sizing: border-box; /* Ensure padding doesn't affect the layout width */
}

.nav-link {
  font-size: 18px; /* Slightly smaller for better fit on small screens */
  font-family: "Helvetica";
  text-decoration: none;
  position: relative;
  color: #696a50;
  font-weight: 600;
  padding: 5px 10px; /* Add padding for easier touch interaction */
}

.nav-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* SUB NAVBAR */
.sub-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  background-color: rgba(255, 228, 196, 0);
  padding: 10px 0;
}

.sub-navbar-link {
  font-size: 12px;
  font-family: "Helvetica";
  text-decoration: none;
  color: #1e1e1e;
  transition: text-decoration 0.3s ease;
}

.sub-navbar-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack links vertically on smaller screens */
    align-items: flex-start; /* Align links to the left */
    padding: 15px;
  }

  .nav-link {
    font-size: 16px; /* Adjust font size for small screens */
    padding: 10px 0; /* Increase padding for easier interaction */
  }

  .sub-navbar {
    justify-content: flex-start; /* Align sub-navbar to the top */
    padding: 15px 0;
  }

  .sub-navbar-link {
    font-size: 14px; /* Slightly smaller text for sub-links */
    padding: 5px 10px;
  }
}

