/* Ensure full background coverage */
html, body {
  height: 100%; /* Set height to 100% */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  background-color: #fcfbf4; /* Set background color for the entire page */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App-parent-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.App-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Set max width for content to match navbar */
  padding: 20px; /* Add some padding to the container */
}

.content-container {
  width: 100%; /* Full width inside the container */
  padding: 20px; /* Padding around the content */
}

/* General App Styling */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-bilde {
  height: 50vmin;
}

.App-navy {
  color: #b1bfa5;
  font-family: "Helvetica", sans-serif;
  font-size: large;
  font-style: italic;
  align-items: center;
}

/* Text Styling */
.App-green-medium {
  color: #696a50;
  font-family: "Helvetica", sans-serif;
}

.App-header,
.App-header-frontpage {
  text-align: center;
  font-style: italic;
  font-family: "Helvetica", sans-serif;
  justify-content: center;
  padding-top: 20px; /* Added padding to top */
  font-size: 40px;
  color: #696a50;
  font-weight: 600;
  margin-bottom: 40px; /* Increased space below the header */
}

.App-header2 {
  text-align: center;
  color: #696a50;
  font-style: italic;
  font-family: "Helvetica", sans-serif;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 30px; /* Increased space below this header */
}

/* Additional Styling for Description */
.App-program-beskrivelse,
.App-program-beskrivelse-siste,
.App-frontpage2,
.App-overnatting3 {
  text-align: center;
  color: #696a50;
  font-style: italic;
  font-family: "Helvetica", sans-serif;
  justify-content: center;
  font-weight: 200;
  margin: 30px 0; /* Increased margin for spacing between sections */
  font-size: 23px;
}

.App-overnatting3 {
  font-size: 23px;
  margin-bottom: 40px; /* Extra space after each block */
}

/* Responsive Design for Small Screens */
@media screen and (max-width: 768px) {
  .App-header,
  .App-header-frontpage {
    font-size: 30px;
  }

  .App-program-beskrivelse,
  .App-program-beskrivelse-siste,
  .App-frontpage2,
  .App-overnatting3 {
    font-size: 18px;
  }

  .App-img-container,
  .App-img-container-overnatting {
    gap: 20px;
  }

  .App-header,
  .App-header-frontpage {
    margin-bottom: 50px; /* Added more space for mobile view */
  }
}

@media screen and (max-width: 480px) {
  .App-logo {
    height: 30vmin;
  }

  .App-bilde {
    height: auto;
    width: 100%;
    padding-bottom: 5%;
  }

  .App-header,
  .App-header-frontpage {
    font-size: 24px;
    padding-top: 10%;
  }

  .App-header2 {
    font-size: 20px;
    margin-bottom: 30px; /* More margin for mobile view */
  }

  .App-program-beskrivelse,
  .App-program-beskrivelse-siste,
  .App-frontpage2,
  .App-overnatting3 {
    font-size: 16px;
    margin: 15px 0; /* Increased margin for mobile view */
  }

  .App-img-container,
  .App-img-container-overnatting {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .App-bilde-overnatting {
    transform: none;
    width: 100%;
    padding-top: 10%;
  }
}

